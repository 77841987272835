import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_biha/assets/ts/_utils'
import {SITE_MAP, WithChildren} from '../../_biha/helpers'
import {MasterLayout} from '../../_biha/layout/MasterLayout'

const PrivateRoutes = () => {
  const UsersPage = lazy(() =>
    import('../modules/apps/upgrade-account-management/UpgradeAccountsPage').then((module) => ({
      default: module.UpgradeAccountsPage,
    }))
  )
  const SocialPurchaseManagementPage = lazy(() =>
    import('../modules/apps/social-purchase-management/SocialPurchaseManagementPage').then((module) => ({
      default: module.SocialPurchaseManagement,
    }))
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/upgrade-account' />} />
        {/* Lazy Modules */}
        <Route
          path='upgrade-account/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${SITE_MAP.SOCIAL_PURCHASE_MANAGEMENT.INDEX}/*`}
          element={
            <SuspensedView>
              <SocialPurchaseManagementPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
