import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {LocaleProvider} from '../_biha/i18n/LocaleProvider'
import {MasterInit} from '../_biha/layout/MasterInit'
import {LayoutProvider, LayoutSplashScreen} from '../_biha/layout/core'
import {ToastProvider} from './modules/apps/components/toast/Toast'
import {AuthInit} from './modules/auth'
import {AddressProvider} from './modules/major/address/AddressProvider'
import {ConfigurationProvider} from './modules/major/config/ConfigurationProvider'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LocaleProvider>
        <LayoutProvider>
          <AuthInit>
            <AddressProvider>
              <ConfigurationProvider>
                <ToastProvider>
                  <Outlet />
                </ToastProvider>
                <MasterInit />
              </ConfigurationProvider>
            </AddressProvider>
          </AuthInit>
        </LayoutProvider>
      </LocaleProvider>
    </Suspense>
  )
}

export {App}
