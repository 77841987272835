const QUERIES = {
  CONFIG: 'config',
  CUSTOMERS_LIST: 'customers-list',
  DEMAND_LIST: 'demand-list',
  PROVINCE_LIST: 'province-list',
  DISTRICT_LIST: 'district-list',
  WARD_LIST: 'ward-list',
  STREET_LIST: 'street-list',
  COMMENT: 'comment',
  UPGRADE_ACCOUNT_LIST: 'upgrade-account-list',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  SOCIAL_PURCHASE_LIST: 'social-purchare-list',
}
const SITE_MAP = {
  SOCIAL_PURCHASE_MANAGEMENT: {
    INDEX: 'social-purchase-management',
    LIST: 'list',
    SLUG_ID: ':id',
    NEW: 'new',
    EDIT: 'edit',
    RESUBMIT: 'resubmit',
    OVERVIEW: 'overview',
    EDIT_HISTORY: 'edit-history',
    INTERACTION_HISTORY: 'interaction-history',
    NOTE: 'note'
  },
  UPGRADE_ACCOUNT_MANAGEMENT: {
    INDEX: 'upgrade-account',
    LIST: 'list',
    SLUG_ID: ':id',
    NEW: 'new',
    EDIT: 'edit',
  },
}

export {QUERIES, SITE_MAP}
